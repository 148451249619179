import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/nav.scss";

class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }
  handleClick() {
    setTimeout(this.toggleMenu, 300);
  }
  render() {
    return (
      <nav id="navBar">
        <div className="menu" onClick={this.toggleMenu}>
          <FontAwesomeIcon icon={this.state.menuOpen ? "times" : "bars"} />
        </div>
        <ul className={`menuList${this.state.menuOpen ? " show" : ""}`}>
          <li onClick={this.handleClick}>
            <a href="#home">home</a>
          </li>
          <li onClick={this.handleClick}>
            <a href="#about">about</a>
          </li>
          <li onClick={this.handleClick}>
            <a href="#projects">projects</a>
          </li>
          <li onClick={this.handleClick}>
            <a href="#contact">contact</a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Nav;
