import React from "react";
import Header from "components/header";
import Home from "components/home";
import About from "components/about";
import Projects from "components/projects";
import Contact from "components/contact";
import "../styles/container.scss";

class Container extends React.Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Home />
        <About />
        <Projects />
        <Contact />
      </div>
    );
  }
}

export default Container;
