import React from "react";
import "styles/shared/button.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Button extends React.Component {
  render() {
    let text = this.props.text;
    if (this.props.isSubmitting) {
      text = <FontAwesomeIcon icon="spinner" spin={true} />;
    }
    return (
      <button className="btn" type={this.props.type}>
        {text}
      </button>
    );
  }
}

export default Button;
