import React from "react";
import Nav from "./nav";
import logo from "../images/logo.svg";
import $ from "jquery";
import "../styles/header.scss";

class Header extends React.Component {
  componentDidMount() {
    $(document).scroll(function() {
      let h = $(window).scrollTop();
      if (h >= $("#home h1").offset().top) {
        $("header").addClass("scrolling");
      } else {
        $("header").removeClass("scrolling");
      }
    });
  }
  render() {
    return (
      <header id="header">
        <img className="logo" src={logo} alt="jz logo" />
        <Nav />
      </header>
    );
  }
}

export default Header;
