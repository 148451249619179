import React, { useEffect } from "react";
import Bar from "./bar";
import data from "data/skills";
import "styles/about/skills.scss";

const Skills = (props) => {
	useEffect(() => {
		const skillsEl = document.getElementById("skills");
		let timer;
		function onScroll(e) {
			e.preventDefault();
			if (!timer) {
				skillsEl.scrollBy({ left: e.deltaY * 1.5 });
				timer = setTimeout(() => (timer = null), 100);
			}
		}

		skillsEl.addEventListener("wheel", onScroll);
		return () => {
			skillsEl.removeEventListener("wheel", onScroll);
		};
	}, []);
	return (
		<>
			<h2>My Skills</h2>
			<div id="skills">
				{data.skills.map((skill, i) => {
					return (
						<div className="area" key={i}>
							<h3>
								<span>{skill.area}</span>
							</h3>
							{skill.results.map((item, i) => {
								return (
									<Bar
										key={i}
										name={item.name}
										shorthand={item.shorthand}
										rank={item.rank}
									/>
								);
							})}
						</div>
					);
				})}
			</div>
		</>
	);
};

export default Skills;
