import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "styles/projects/devices.scss";

const Device = props => (
  <>
    <div className={`${props.type}-container`}>
      <img
        className={`project-${props.type}${props.hovering ? " hovering" : ""}`}
        src={props.img}
        alt={`${props.type} view of ${props.name}`}
      />
    </div>

    <img className={props.type} src={props.svg} alt={props.type} />
    <div
      className={`${props.type}-hotspot`}
      onMouseEnter={() => props.displayControls(props.type, true)}
      onMouseLeave={() => props.displayControls(props.type, false)}
      onClick={() => props.toggleButton(props.type)}
    >
      <FontAwesomeIcon icon={props.icon} className="play-button" />
    </div>
  </>
);

export default Device;
