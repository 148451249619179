import React from "react";
import Skills from "./skills";
import jake from "images/jake-headshot.png";
import "styles/about/index.scss";

class About extends React.Component {
	render() {
		return (
			<section id="about">
				<div className="band"></div>
				<div className="container">
					<h1>About</h1>
					<div id="info">
						<div className="blurb">
							<h2>I'm Jake Zeitz</h2>
							<p>
								I love to code! I've worked professionally in
								the field of web development since 2013. My
								passion for designing and building awesome user
								interfaces may just lead you to your next web
								project.
							</p>
						</div>
						<div
							className="headshot"
							style={{ backgroundImage: `url(${jake})` }}></div>
					</div>
					<Skills />
				</div>
			</section>
		);
	}
}

export default About;
