import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/home.scss";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { animate: false };
  }
  componentDidMount() {
    requestAnimationFrame(() => {
      // Firefox will sometimes merge changes that happened here
      requestAnimationFrame(() => {
        this.setState({ animate: true });
      });
    });
  }
  render() {
    let animClass = this.state.animate ? "animate" : "";
    return (
      <section id="home">
        <div className="container">
          <h1>
            <span className={`part1 ${animClass}`}>I build websites</span>
            <br />
            <span className={`part2 ${animClass}`}>that work.</span>
          </h1>
          <div id="scrollDown" className={animClass}>
            <div className="line"></div>
            <span>scroll down</span>
            <div className="line"></div>
            <FontAwesomeIcon icon="chevron-circle-down" />
          </div>
        </div>
      </section>
    );
  }
}

export default Home;
