import React from "react";
import "styles/projects/nav.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProjectsNav = props => {
  const lastIndex = props.projects.length - 1;
  const nextIndex = props.index < lastIndex ? props.index + 1 : 0;
  const prevIndex = props.index > 0 ? props.index - 1 : lastIndex;
  return (
    <ul className="dots">
      <li className="previous mr-4" onClick={() => props.loadImage(prevIndex)}>
        <FontAwesomeIcon icon="chevron-circle-left" />
      </li>
      {props.projects.map((project, i) => {
        return (
          <li
            className={props.index === i ? "dot active" : "dot"}
            key={project.id}
            onClick={() => props.loadImage(i)}
          ></li>
        );
      })}
      <li className="next ml-4" onClick={() => props.loadImage(nextIndex)}>
        <FontAwesomeIcon icon="chevron-circle-right" />
      </li>
    </ul>
  );
};
export default ProjectsNav;
