import React from "react";
import Device from "./device";
import ProjectsNav from "./nav";
import data from "data/projects";
import laptop from "images/laptop.svg";
import phone from "images/phone.svg";
import phoneStraight from "images/phone-straight.svg";
import $ from "jquery";
import "styles/projects/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      images: {
        laptop: null,
        phone: null
      },
      hover: {
        laptop: false,
        phone: false
      },
      animate: {
        laptop: "play",
        phone: "play"
      },
      mediaQuery: "desktop"
    };
    this.loadImage = this.loadImage.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.displayControls = this.displayControls.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
  }
  componentDidMount() {
    this.loadImage(0);

    $(window).resize(() => {
      this.handleResize(this.state.mediaQuery);
    });
  }
  handleResize(mediaQuery) {
    const w = window.innerWidth;
    const newQuery = w < 768 ? "mobile" : "desktop";
    if (newQuery !== mediaQuery) {
      this.setState({
        mediaQuery: newQuery
      });
    }
  }
  async loadImage(index) {
    const laptopSrc = data.projects[index].laptopFull;
    const phoneSrc = data.projects[index].phoneFull;

    const laptopImg = await import(`images/${laptopSrc}`);
    const phoneImg = await import(`images/${phoneSrc}`);

    const mediaQuery = window.innerWidth < 768 ? "mobile" : "desktop";

    this.setState(
      {
        index: index,
        images: {
          laptop: laptopImg.default,
          phone: phoneImg.default
        },
        animate: {
          laptop: "play",
          phone: "play"
        },
        mediaQuery: mediaQuery
      },
      () => {
        $(".project-laptop, .project-phone")
          .stop()
          .animate({ top: 0 }, 300, "linear");
      }
    );
  }
  animateScreen(type, icon, callback) {
    callback = typeof callback === "undefined" ? "" : callback;
    icon = typeof icon === "undefined" ? "" : icon;
    if (type !== "reset") {
      const el = $(`.project-${type}`),
        container = $(`.${type}-container`);
      const pos = Math.abs(parseInt(el.css("top")));
      const h = icon !== "redo" ? el.height() - container.height() - pos : pos;
      const speed = icon !== "redo" ? 20 : 200;
      const time = (h / speed) * 100;
      const top = icon !== "redo" ? -(h + pos) : 0;
      el.stop();
      if (icon !== "pause") {
        el.animate({ top: top }, time, "linear", callback);
      }
    } else {
      $(".project-laptop, .project-phone")
        .stop()
        .animate({ top: 0 }, 300);
    }
  }
  displayControls(type, hovering) {
    this.setState(prevState => ({
      hover: {
        ...prevState.hover,
        [type]: hovering
      }
    }));
  }
  toggleButton(type) {
    const clickedIcon = this.state.animate[type];
    this.setState(
      prevState => ({
        animate: {
          ...prevState.animate,
          [type]:
            this.state.animate[type] === "play" ||
            this.state.animate[type] === "redo"
              ? "pause"
              : "play"
        }
      }),
      this.animateScreen(type, this.state.animate[type], () => {
        this.setState(prevState => ({
          animate: {
            ...prevState.animate,
            [type]: clickedIcon !== "redo" ? "redo" : "play"
          }
        }));
      })
    );
  }
  render() {
    const project = data.projects[this.state.index];
    const types = ["laptop", "phone"];
    return (
      <section id="projects">
        <div className="band"></div>
        <div className="container">
          <h1>Projects</h1>
          <div className="project">
            <div className="devices">
              {types.map(type => {
                let svg = laptop;
                if (type === "phone") {
                  svg =
                    this.state.mediaQuery === "mobile" ? phoneStraight : phone;
                }
                return (
                  <Device
                    key={type}
                    name={project.name}
                    type={type}
                    svg={svg}
                    img={this.state.images[type]}
                    displayControls={this.displayControls}
                    toggleButton={this.toggleButton}
                    hovering={this.state.hover[type]}
                    icon={this.state.animate[type]}
                  />
                );
              })}
            </div>
            <div className="info">
              <h2>
                {project.name}
                <a
                  className="ml-4"
                  href={project.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="Visit Site"
                >
                  <FontAwesomeIcon icon="external-link-alt" />
                </a>
              </h2>

              <div className="desc">{project.desc}</div>
              <ProjectsNav
                loadImage={this.loadImage}
                index={this.state.index}
                projects={data.projects}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Projects;
