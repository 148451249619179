import React from "react";
import ContactForm from "./form";
import plane from "images/paper-airplane.png";
import "styles/contact/index.scss";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Contact extends React.Component {
  render() {
    return (
      <section id="contact">
        <div className="band"></div>
        <div className="container">
          <h1>Contact</h1>
          <div className="row">
            <div className="col-md">
              <img
                className="paper-airplane"
                src={plane}
                alt="paper airplane"
              />
            </div>
            <div className="col-md">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
