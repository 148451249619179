import React from "react";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Button from "components/shared/button";
import $ from "jquery";
import "styles/contact/form.scss";
import ReactGA from "react-ga";

const handleSubmit = (values, actions) => {
	ReactGA.event({
		category: "Form",
		action: "Contact Form Submit",
		transport: "beacon",
	});
	axios.post("/email", values).then((res) => {
		actions.setSubmitting(false);
		actions.setStatus({
			success: res.data.success,
			message: res.data.message,
		});
		setTimeout(() => {
			actions.resetForm();
		}, 3000);
		$(".btn").blur();
	});
};

const FormField = ({ label, tagName, ...props }) => {
	const [field, meta] = useField(props);
	const errorClass = meta.touched && meta.error ? "error" : "";
	const TagName = tagName;
	return (
		<>
			<label htmlFor={props.id || props.name}>
				{label}
				{meta.touched && meta.error ? (
					<span className="error">{meta.error}</span>
				) : null}
			</label>
			<TagName
				className={`form-control ${errorClass}`}
				{...field}
				{...props}
			/>
		</>
	);
};

const ContactForm = () => (
	<Formik
		initialValues={{ name: "", email: "", msg: "" }}
		validationSchema={Yup.object({
			name: Yup.string().required("Required"),
			email: Yup.string()
				.email("Invalid email address")
				.required("Required"),
			msg: Yup.string().required("Required"),
		})}
		onSubmit={handleSubmit}>
		{({ isSubmitting, status }) => (
			<Form className="contact-form">
				<div className="row form-group">
					<div className="col">
						<FormField
							label="Name"
							name="name"
							type="text"
							tagName="input"
						/>
					</div>
					<div className="col">
						<FormField
							label="Email"
							name="email"
							type="text"
							tagName="input"
						/>
					</div>
				</div>
				<div className="form-group">
					<FormField label="Message" name="msg" tagName="textarea" />
				</div>
				{status && status.message && (
					<div
						className={`message ${
							status.success ? "success" : "error"
						}`}>
						{status.message}
					</div>
				)}
				<Button
					id="formSubmit"
					text="Send Message"
					type="submit"
					isSubmitting={isSubmitting}
				/>
			</Form>
		)}
	</Formik>
);

export default ContactForm;
