import React, { useEffect, useState, useRef } from "react";
import "styles/about/bars.scss";

const Bar = (props) => {
	const [isLeft, setIsLeft] = useState(true);
	const itemRef = useRef();
	useEffect(() => {
		const skillsEl = document.getElementById("skills");
		function onScroll() {
			if (itemRef.current) {
				const { x } = itemRef.current.getBoundingClientRect();
				const tagWidth = itemRef.current.querySelector(".bar-info")
					.offsetWidth;
				setIsLeft(x + tagWidth / 2 < skillsEl.offsetWidth);
			}
		}
		onScroll();
		skillsEl.addEventListener("scroll", onScroll);
		return () => {
			skillsEl.removeEventListener("scroll", onScroll);
		};
	}, [itemRef]);
	return (
		<div className="item" ref={itemRef}>
			<div
				className={`bar ${isLeft ? "left" : "right"}`}
				style={{
					height: `calc(${props.rank * 10}% - 30px`,
				}}>
				<div className="wrapper">
					<div className="bar-info">
						<div className="full-name">{props.name}</div>
					</div>
				</div>
			</div>
			<span className="name">{props.shorthand}</span>
		</div>
	);
};

export default Bar;
