import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Container from "components/container";
import ReactGA from "react-ga";
import axios from "axios";

const trackingId = "UA-35089796-1";

ReactGA.initialize(trackingId);

ReactGA.pageview("/");

axios.defaults.baseURL = process.env.REACT_APP_API;

function App() {
	return (
		<Router>
			<Route path="/" exact component={Container} />
		</Router>
	);
}

export default App;
